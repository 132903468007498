/**
 * 获取空间位置的树形结构
 */
export const getDeviceGroupTreeApi = () => useGet<Tree<DeviceGroupTreeNode>[]>('/deploy/device-group/tree')

/**
 * 新增空间位置
 */
export const addDeviceGroupApi = (data: any) => usePost('/deploy/device-group', data)

/**
 * 修改空间位置
 */
export const updateDeviceGroupApi = (data: any) => usePut('/deploy/device-group', data)

/**
 * 通过id查询空间位置
 */
export const getDeviceGroupByIdApi = (id: number) => useGet(`/deploy/device-group/${id}`)

/**
 * 通过id删除空间位置
 */
export const deleteDeviceGroupByIdApi = (id: number) => useDelete(`/deploy/device-group/${id}`)

/**
 * 批量关联设备和设备组
 */
export const addDeviceGroupRelationsApi = (data: any) => usePost('/deploy/device-group/relations', data)

/**
 * 批量删除关联的设备和设备组
 */
export const deleteDeviceGroupRelationsApi = async (data: any) => useDelete('/deploy/device-group/relations', data)
